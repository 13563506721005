import React, { Component } from 'react' 
import { Container } from '@material-ui/core'; 
import AppBar from '@material-ui/core/AppBar' 
import Toolbar from '@material-ui/core/Toolbar' 
import Typography from '@material-ui/core/Typography' 
import { Button } from '@material-ui/core'; 
import { withRouter } from 'react-router-dom'; 
import { Auth } from 'aws-amplify';

 
class NavBar extends Component { 
 
  constructor(props) { 
    super(props); 
    this.handleClick = this.handleClick.bind(this); 
  } 
 
  handleClick=async event => { 
    event.preventDefault(); 
      try {
        Auth.signOut();
        this.props.auth.setAuthStatus(false);
        this.props.history.push("/index.html")
      }
      catch (error) {
        console.log(error.message)
      }
    
  } 

  render() { 
    return ( 
      <div className="navbar-wrapper"> 
        <AppBar position="static" color="default"> 
          <Container maxWidth="lg"> 
            <Toolbar> 
              <Typography variant="h6" color="inherit" className="title"> 
                <img src="/assets/img/img5.png" alt="logo"/> <a href="/"><span className="title-text">cms</span></a> 
              </Typography> 
              {this.props.auth.isAuthenticated&& (
                <div className="menu-button"> 
                  <span className="page-title">Admin Portal</span> 
                  <Button className="blue-btn" onClick={this.handleClick}> 
                    Logout 
                  </Button>  </div>)}
            </Toolbar> 
          </Container> 
        </AppBar> 
      </div> 
    ) 
  } 
} 
 
export default withRouter(NavBar);
