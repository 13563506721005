import React, { Component } from 'react' 
import {Route, Switch } from "react-router-dom"; 
import UploadTagImagesPage from './UploadTagImagePage'
import UploadAssetPage from './UploadAssetPage'
import DatePickerPage from './DatePickerPage'
import LandingPage from './LandingPage'
import EditARExpPage from './EditARExpPage'
import ApiActionPage from './ApiActionPage'
import CropTagImagePage from './CropTagImagePage'
import InsertTitlePage from './InsertTitlePage'

class RoutingPage extends Component { 
    render() { 
        return ( 
            <React.Fragment> 
                {/* TO DO: Refine Routing Page and Base Page for routing */} 
                <div className="App">
                <Switch> 
                    <Route exact path="/index.html"   render={()=><LandingPage {...this.props} auth={this.props.auth}></LandingPage>} /> 
                    <Route exact path="/"   render={()=><LandingPage {...this.props} auth={this.props.auth}></LandingPage>} /> 
                    <Route exact path="/uploadasset/:handle" component={UploadAssetPage} /> 
                    <Route exact path="/datepicker/:handle" component={DatePickerPage} /> 
                    <Route exact path="/croptagimage/:handle" component={CropTagImagePage} /> 
                    <Route exact path="/uploadtagimage/:handle" component={UploadTagImagesPage} /> 
                    <Route exact path="/editarexp/:handle" component={EditARExpPage}  /> 
                    <Route exact path="/apiaction/:handle" component={ApiActionPage} /> 
                    <Route exact path="/inserttitle/:handle" component={InsertTitlePage}/> 
                    <Route render={ () =>  
                        <div>404 NOT FOUND</div> 
                    }/> 
                </Switch>
                </div> 
            </React.Fragment> 
        ) 
    } 
} 
 
export default RoutingPage 