import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

class ImgDropAndCrop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.location.state.id,
            title: this.props.location.state.title,
            imgSrc: this.props.location.state.tagImageUrl,
            chkVal: null,
            valMessage: null
        }

    }

    handleBack = event => {
        this.props.history.push({
            pathname: `/inserttitle/${this.state.id}`,
            state: this.props.location.state
        })
    }

    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > imageMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                alert("This file is not allowed. Only images are allowed.")
                return false
            }
            return true
        }
    }

    handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = myFileItemReader.result
                    this.setState({
                        imgSrc: myResult,
                    })
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            }
        }
    }

    handleNextClick = async (event) => {
        event.preventDefault()
        this.setState({ chkVal: null, valMessage: null })
        const { imgSrc } = this.state
        if (imgSrc === "") {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please insert tag image" })
        }
        else {
            this.props.location.state.tagImageUrl = this.state.imgSrc
            this.props.location.state.title = this.state.title
            this.props.history.push({
                pathname: `/croptagimage/${this.state.id}`,
                state: this.props.location.state
            })
        }
    }

    handleClearToDefault = event => {
        if (event) event.preventDefault()
        this.setState({
            imgSrc: ""
        })
    }
    render() {
        const { imgSrc } = this.state
        return (
            <React.Fragment>
                {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Create AR Experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <div className="processTitleDiv">
                                <h3 className="processTitle">{this.state.title}</h3>
                            </div>
                            <div className="ProgressBar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconActive"> <img src="/assets/img/icon1.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon2.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon3.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon4.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon5.svg" alt="icon1"></img></Fab></td>
                                        </tr>
                                        <tr>
                                            <td><p className="iconRange">Upload Image</p></td>
                                            <td><p className="iconRange2">Crop Image</p></td>
                                            <td ><p className="iconRange2">Upload Asset</p></td>
                                            <td><p className="iconRange2">Effective Period</p></td>
                                            <td><p className="iconRange2">Call to Action</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg"></img></div>
                        <br /> <br /> <br />
                        <div className="tagImg">
                            {imgSrc !== "" ?
                                <div >
                                    <img src={imgSrc} alt="arrow" className="dropImage" />
                                    <br /><br />
                                    <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearToDefault} className="clearBtn">Clear</Fab>
                                </div>
                                :
                                <div className="imgDrop">
                                    <Dropzone onDrop={this.handleOnDrop} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize} className="dropZone" ></Dropzone>
                                </div>
                            }
                        </div>
                        <br />
                        <div className="nextIcon">
                            <Fab variant="extended" size="small" aria-label="add" onClick={this.handleNextClick} className="arButton3">Next&nbsp;&nbsp;&nbsp;<img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1" ></img>
                            </Fab>
                        </div>
                        <br /><br /><br /><br />
                    </Typography>
                    <br /> <br />
                </Container>
            </React.Fragment>
        )
    }
} export default ImgDropAndCrop
