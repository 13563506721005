import React, { Component } from 'react'
import ImgAsset from '../components/ImgAsset'


class UploadAssetPage extends Component {
    
    render () {
        return (
      <div>
          <ImgAsset jobid={this.props.match.params.handle} {...this.props}></ImgAsset>
      </div>
        )
    }
}
export default UploadAssetPage