import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';


class InsertTitle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.location.state.id,
            title: this.props.location.state.title,
            chkVal: null,
            valMessage: null
        }
        this.handleTitleChange = this.handleTitleChange.bind(this);
    }

    handleTitleChange(event) {
        this.setState({ title: event.target.value });
    }

    handleBack = event => {
        this.props.history.push("/index.html")
    }

    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }

    handleNextClick = async (event) => {
        event.preventDefault()
        this.setState({ chkVal: null, valMessage: null })
        const { title } = this.state
        var str = title;
        if (str.trim() === "") {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please provide experience name" })
        }
        else {
            this.props.location.state.title = this.state.title
            this.props.history.push({
                pathname: `/uploadtagimage/${this.state.id}`,
                state: this.props.location.state
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Create AR Experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <div className="processTitleDiv">
                                <h3 className="processTitle">What's your AR experience name?</h3>
                            </div>
                            <div className="ProgressBar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconNonActive"> <img src="/assets/img/icon1.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon2.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon3.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon4.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon5.svg" alt="icon1"></img></Fab></td>
                                        </tr>
                                        <tr>
                                            <td><p className="iconRange">Upload Image</p></td>
                                            <td><p className="iconRange2">Crop Image</p></td>
                                            <td ><p className="iconRange2">Upload Asset</p></td>
                                            <td><p className="iconRange2">Effective Period</p></td>
                                            <td><p className="iconRange2">Call to Action</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg"></img></div>
                        <br /> <br /> <br />
                        <TextField label="Experience Name" value={this.state.title} onChange={this.handleTitleChange} margin="dense" />
                        <br /><br /> <br /><br /><br /> <br />
                        <div className="nextIcon">
                            <Fab variant="extended" size="small" aria-label="add" onClick={this.handleNextClick} className="arButton3" >
                                Next&nbsp;&nbsp;&nbsp;<img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1" ></img>
                            </Fab>
                        </div>
                        <br /> <br /> <br /> <br />
                    </Typography>
                    <br />
                    <br />
                </Container>
            </React.Fragment>
        )
    }
} export default InsertTitle
