import React, { Component } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import NavBar from './../components/NavBar'
import RoutingPage from './RoutingPage'

class BasePage extends Component {

    render() {
        return (
            <Router>
                <NavBar auth={this.props.auth}/>
                <RoutingPage auth={this.props.auth}/>
            </Router>
        )
    }

}

export default BasePage 