import React, { Component } from 'react'
import ApiAction from '../components/ApiAction'

class ApiActionPage extends Component {
   
    render () {
        return (
            <div>
                <ApiAction jobid={this.props.match.params.handle} {...this.props}></ApiAction>
           </div>
        )
    }
}
export default ApiActionPage