import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import { setApi } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

class ApiAction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.location.state.id,
            buttonTitle: "",
            buttonLink: "",
            title: this.props.location.state.title,
            waiting: false,
            chkVal: null,
            valMessage: null
        }
        this.handleButtonTitleChange = this.handleButtonTitleChange.bind(this);
        this.handleButtonLinkChange = this.handleButtonLinkChange.bind(this);
    }
    handleComplete = event => {
        this.props.history.push("/index.html")
    }
    handleButtonTitleChange(event) {
        this.setState({ buttonTitle: event.target.value });
    }

    handleButtonLinkChange(event) {
        this.setState({ buttonLink: event.target.value });
    }

    handleSetApi = async (event) => {
        event.preventDefault();
        this.setState({ chkVal: null, valMessage: null })
        const { buttonTitle } = this.state
        const { buttonLink } = this.state
        if (this.state.buttonTitle.length > 20) {
            this.setState({ waiting: false, chkVal: false, valMessage: "Title should be less than 20 words" })
            return;
        }
        else if (buttonTitle && buttonLink) {
            const params = {
                id: this.state.id,
                status: "Live",
                buttonTitle: this.state.buttonTitle,
                buttonLink: this.state.buttonLink,
                interupt: "true"
            };
            try {
                this.setState({ waiting: true })
                const response = await API.graphql(graphqlOperation(setApi, params));
                console.log(response)
                await this.setState({ waiting: false, chkVal: true, valMessage: "Created successfully" })
                setTimeout(this.handleComplete, 1000);
            } catch (err) {
                console.log(err)
                this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
            }
        }
        else {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please insert button title and button link" })
        }
    }

    handleDraftApi = async (event) => {
        event.preventDefault();
        this.setState({ chkVal: null, valMessage: null })
        var buttonLink = this.state.buttonLink
        var buttonTitle = this.state.buttonTitle
        if (!buttonTitle || !buttonLink) {
            buttonLink = "No-Action";
            buttonTitle= "No-Action";
        }
        const params = {
                id: this.state.id,
                status: "Draft",
                buttonTitle: buttonTitle,
                buttonLink: buttonLink,
                interupt: "true"
            };
            try {
                this.setState({ waiting: true })
                const response = await API.graphql(graphqlOperation(setApi, params));
                console.log(response)
                await this.setState({ waiting: false, chkVal: true, valMessage: "Saved Draft successfully" })
                setTimeout(this.handleComplete, 1000);
            } catch (err) {
                console.log(err)
                this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
            }  
    }

    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }

    handleSkipApi = async (event) => {
        event.preventDefault();
        this.setState({ chkVal: null, valMessage: null })
        const params = {
            id: this.state.id,
            status: "Live",
            buttonTitle: "No-Action",
            buttonLink: "No-Action",
            interupt: "true"
        };
        try {
            this.setState({ waiting: true })
            const response = await API.graphql(graphqlOperation(setApi, params));
            console.log(response)
            await this.setState({ waiting: false, chkVal: true, valMessage: "Created successfully" })
            setTimeout(this.handleComplete, 1000);
        } catch (err) {
            console.log(err)
            this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
        }
    }

    handleBack = event => {
        this.props.history.push({
            pathname: `/datepicker/${this.state.id}`,
            state: this.props.location.state
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.chkVal === true && (<div className="alert alert-success sticky" role="alert">
                    <button type="button" className="close-alert">×</button>{this.state.valMessage}
                </div>)}
                {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Create AR Experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <div className="processTitleDiv">
                                <h3 className="processTitle">{this.state.title}</h3>
                            </div>
                            <div className="ProgressBar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconActive"> <img src="/assets/img/icon1.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon2.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon3.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon4.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon5.svg" alt="icon1"></img></Fab></td>
                                        </tr>
                                        <tr>
                                            <td><p className="iconRange">Upload Image</p></td>
                                            <td><p className="iconRange2">Crop Image</p></td>
                                            <td ><p className="iconRange2">Upload Asset</p></td>
                                            <td><p className="iconRange2">Effective Period</p></td>
                                            <td><p className="iconRange2">Call to Action</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg2"></img></div>
                        <br /><br />
                        <div className="apiAction">
                            <h3 className="title2">Call to action</h3>
                            <div>
                                <TextField label="Button Title" value={this.state.buttonTitle} onChange={this.handleButtonTitleChange} margin="dense" className="apiBtnTitle" />
                                <br />
                                <TextField label="Button Action" value={this.state.buttonLink} onChange={this.handleButtonLinkChange} margin="dense" className="apiBtnLink" />
                            </div>
                        </div>
                        <br /><br /><br /><br />
                        <div className="nextIcon">
                        <Fab variant="extended" size="small" aria-label="add" onClick={this.handleDraftApi} className="arButton4" disabled={this.state.waiting}>
                               <div className="apiBtn">Save as Draft</div>
                            </Fab>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Fab variant="extended" size="small" aria-label="add" onClick={this.handleSkipApi} className="arButton4" disabled={this.state.waiting}>
                            <div className="apiBtn">Skip</div>&nbsp;&nbsp;<img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1"></img>
                                <img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1"></img>
                            </Fab>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Fab variant="extended" size="small" aria-label="add" onClick={this.handleSetApi} className="arButton3" disabled={this.state.waiting}>
                            <div className="apiBtn">Next</div>&nbsp;&nbsp;<img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1"></img>
                            </Fab>
                        </div>
                        <br /><br /><br />
                        {this.state.waiting && (<div><CircularProgress className="loading" /><p className="loadingText">Processing</p></div>)}
                        <br /><br />
                    </Typography>
                </Container>
                <br /><br />
            </React.Fragment>
        )
    }
} export default ApiAction