import React, { Component } from 'react'
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { API, graphqlOperation } from 'aws-amplify';
import { setDatePeriod } from '../graphql/mutations';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

class EffectiveDate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.location.state.id,
            startDate: null,
            endDate: null,
            strD: null,
            endD: null,
            displayStartDate: this.props.location.state.startDate,
            displayEndDate: this.props.location.state.endDate,
            title: this.props.location.state.title,
            waiting: false,
            chkVal: null,
            valMessage: null
        }
    }

    handlerDate = async (event) => {
        if (this.state.startDate != null) {
            var strD = new Date(this.state.startDate._d);
            const sdd = String(strD.toISOString())
            this.setState({ displayStartDate: sdd })
        }
        if (this.state.endDate != null) {
            var EndD = new Date(this.state.endDate._d);
            const edd = String(EndD.toISOString())
            this.setState({ displayEndDate: edd })
        }
    }

    handlerOnEffectiveDate = async (event) => {
        if (this.state.startDate && this.state.endDate) {
            event.preventDefault();
            var strD = new Date(this.state.startDate._d);
            var endD = new Date(this.state.endDate._d);
            if (strD <= endD) {
                // const sdd = String(strD.toISOString())
                // const edd = String(EndD.toISOString())
                var datum = new Date(strD.getFullYear(),strD.getMonth(),strD.getDate()-1,strD.getHours()+12,strD.getMinutes(),strD.getSeconds());
                var sdd =datum.getTime()/1000;
                var datum2 = new Date(endD.getFullYear(),endD.getMonth(),endD.getDate(),endD.getHours()+11,endD.getMinutes(),endD.getSeconds());
                var edd =datum2.getTime()/1000;
                this.setState((state, props) => ({ strD: sdd, endD: edd }), this.handlerSubmit)
            }
            else {
                this.setState({ waiting: false,chkVal: false,valMessage:"The start date cannot be larger than the end date" })
            }
        }
        else {
            this.setState({ waiting: false,chkVal: false,valMessage:"Please select an effective period" })
        }
    }

    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }
    
    handlerSubmit = async (event) => {
        const params = {
            id: this.state.id,
            startDate: this.state.strD,
            endDate: this.state.endD,
        };
        this.setState({chkVal: null, valMessage: null})
        try {
            this.setState({ waiting: true })
            const response = await API.graphql(graphqlOperation(setDatePeriod, params));
            console.log(response)
            this.props.location.state.startDate = this.state.displayStartDate
            this.props.location.state.endDate = this.state.displayEndDate
            this.props.history.push({
                pathname: `/apiaction/${this.state.id}`,
                state: this.props.location.state
            })
        } catch (err) {
            console.log(err)
            this.setState({ waiting: false,chkVal: false,valMessage:err.errors[0].message })
        }
    }

    handleBack = event => {
        this.props.history.push({
            pathname: `/uploadasset/${this.state.id}`,
            state: this.props.location.state
        })
    }

    render() {
        return (
            <React.Fragment>
                 {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Create AR Experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <div className="processTitleDiv">
                                <h3 className="processTitle">{this.state.title}</h3>
                            </div>
                            <div className="ProgressBar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconActive"> <img src="/assets/img/icon1.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon2.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon3.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon4.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon5.svg" alt="icon1"></img></Fab></td>
                                        </tr>
                                        <tr>
                                            <td><p className="iconRange">Upload Image</p></td>
                                            <td><p className="iconRange2">Crop Image</p></td>
                                            <td ><p className="iconRange2">Upload Asset</p></td>
                                            <td><p className="iconRange2">Effective Period</p></td>
                                            <td><p className="iconRange2">Call to Action</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg"></img></div>
                        <br /> <br />
                        <div>
                            <h3 className="effPeriodTitle">Effective period</h3>
                        </div>
                        <div>
                            <br />
                            <table className="datePickerTb">
                                <tbody>
                                    <tr>
                                        <td><div className="dateFormat">Start date:</div></td>
                                        <td> <SingleDatePicker
                                            date={this.state.startDate} // momentPropTypes.momentObj or null
                                            onDateChange={startDate => this.setState({ startDate }, this.handlerDate)} // PropTypes.func.isRequired
                                            focused={this.state.focused} // PropTypes.bool
                                            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                            id="1" // PropTypes.string.isRequired,
                                            displayFormat={() => "DD/MM/YYYY"} /></td>
                                    </tr>
                                    <tr>
                                        <td><div className="dateFormat">End date:</div></td>
                                        <td>  <SingleDatePicker
                                            date={this.state.endDate} // momentPropTypes.momentObj or null
                                            onDateChange={endDate => this.setState({ endDate }, this.handlerDate)} // PropTypes.func.isRequired
                                            focused={this.state.focused2} // PropTypes.bool
                                            onFocusChange={({ focused: focused2 }) => this.setState({ focused2 })} // PropTypes.func.isRequired
                                            id="2" // PropTypes.string.isRequired,
                                            displayFormat={() => "DD/MM/YYYY"} /> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <br />
                            {this.state.displayStartDate !== "" && this.state.displayEndDate !== "" ?
                                <p className="effDateText">AR experience will start from  <Moment format="DD/MM/YYYY">{this.state.displayStartDate}</Moment> to  <Moment format="DD/MM/YYYY">{this.state.displayEndDate}</Moment></p> : <p></p>}
                            <div className="nextIcon">
                            <br />
                                <Fab variant="extended" size="small" aria-label="add" onClick={this.handlerOnEffectiveDate} className="arButton3" disabled={this.state.waiting}>
                                    Next&nbsp;&nbsp;<img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1"></img>
                                </Fab>
                            </div>
                        </div>
                        <br /><br /><br /><br />
                        {this.state.waiting && (<div><CircularProgress className="loading" /><p className="loadingText">Processing</p></div>)}
                        <br /><br />
                    </Typography>
                </Container>
                <br /><br />
            </React.Fragment>
        )
    }
} export default EffectiveDate
