import React, { Component } from 'react'
import ArList from '../components/ArList'
import { API, graphqlOperation } from 'aws-amplify';
import { listArImagess } from '../graphql/queries'

class landingPage extends Component {

    state = {
        listData: []
    }

    async fetchARList() {
        const params = {
            "filter": null, 
            "limit": 10000, 
            "nextToken": null
        };
        const response = await API.graphql(graphqlOperation(listArImagess, params));
        if (response.data.listArImagess) {
            if(this.props.auth.userGroup==="superadmin"){
                this.setState({
                    listData: response.data.listArImagess.items
                });
            }
            else{
            var listArDate = []
            for (var i = 0; i < response.data.listArImagess.items.length; i++) {
                if( response.data.listArImagess.items[i].createdBy===this.props.auth.userGroup){
                    listArDate.push(response.data.listArImagess.items[i]);
                }
            }
            this.setState({
                listData: listArDate
            });
        }
        }
    }
    
    componentDidMount() {
        this.fetchARList();          
    }
    
    render () {
        return (
            <div><ArList arExperienceList={this.state.listData} {...this.props}></ArList></div>
        )
    }
}
export default landingPage 