import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { ObjViewer } from 'react-obj-viewer'
import { API, graphqlOperation } from 'aws-amplify';
import { uploadAsset } from '../graphql/mutations'
import Button from '@material-ui/core/Button';
import { base64StringtoFile } from './ResuableUtils'
import { Checkbox } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getArImages } from '../graphql/queries'

const imageMaxSize = 1000000000 // bytes
const acceptedImageTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedVideoTypes = 'video/mpeg, video/ogg, video/mp4'
// const accepted3DObjestTypes = 'application/wavefront-obj, application/object'
const acceptedImagesTypesArray = acceptedImageTypes.split(",").map((item) => { return item.trim() })
const acceptedVideoTypesArray = acceptedVideoTypes.split(",").map((item) => { return item.trim() })
// const accepted3DObjestTypesArray = accepted3DObjestTypes.split(",").map((item) => { return item.trim() })
const uuidv4 = require('uuid/v4');
class ImgAsset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Image: false,
            Video: false,
            DObject: false,
            imgSrc: "",
            videoSrc: "",
            threeDObjectSrc: "",
            id: this.props.location.state.id,
            fullScreenVideo: this.props.location.state.fullScrVideo,
            waiting: false,
            title: this.props.location.state.title,
            tagImg: null,
            chkVal: null,
            valMessage: null,
            nextText: "Next"
        }
    }

    async fetchImage() {
        const params = {
            id: this.state.id
        };
        const response = await API.graphql(graphqlOperation(getArImages, params));
        if (response.data.getArImages) {
            this.setState({ tagImg: response.data.getArImages.tagImageUrl })
        }
    }

    componentDidMount() {
        this.forceUpdate()
        this.checkboxOnChange = this.checkboxOnChange.bind(this)
        this.setChanges = this.setChanges.bind(this)
        this.fetchImage();

    }
    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }
    setChanges(name, value) {
        return new Promise(resolve => this.setState((state, props) => ({
            [name]: value
        }), resolve))
    }

    checkboxOnChange(event) {
        this.setChanges(event.target.name, event.target.checked)
    }

    handleInputImage = async event => { this.setState({ Image: true, Video: false, DObject: false }); }

    handleInputVideo = async event => { this.setState({ Image: false, Video: true, DObject: false }); }

    handleInput3DObject = async event => { this.setState({ Image: false, Video: false, DObject: true }); }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (this.state.Image) {
                if (currentFileSize > imageMaxSize) {
                    alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                    return false
                }
                if (!acceptedImagesTypesArray.includes(currentFileType)) {
                    alert("This file is not allowed. Only images are allowed.")
                    return false
                }
            }
            if (this.state.Video) {
                if (!acceptedVideoTypesArray.includes(currentFileType)) {
                    alert("This file is not allowed. Only Video are allowed.")
                    return false
                }
            }
            // if (this.state.DObject) {
            //     if (!accepted3DObjestTypesArray.includes(currentFileType)) {
            //         alert("This file is not allowed. Only 3DObject are allowed.")
            //         return false
            //     }
            // }
            return true
        }
    }

    handleFileSelect = event => {
        const files = event.target.files
        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = myFileItemReader.result
                    if (this.state.Image) {
                        this.setState({
                            imgSrc: myResult,
                        })
                    }
                    else if (this.state.Video) {
                        this.setState({
                            videoSrc: myResult,
                        })
                    }
                    else if (this.state.DObject) {
                        this.setState({
                            threeDObjectSrc: myResult,
                        })
                    }
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            }
        }
    }

    handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }
        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = myFileItemReader.result
                    if (this.state.Image) {
                        this.setState({
                            imgSrc: myResult,
                        })
                    }
                    else if (this.state.Video) {
                        this.setState({
                            videoSrc: myResult,
                        })
                    }
                    else if (this.state.DObject) {
                        this.setState({
                            threeDObjectSrc: myResult,
                        })
                    }
                }, false)
                myFileItemReader.readAsDataURL(currentFile)

            }
        }
    }

    handleClick = async (event) => {
        event.preventDefault()
        const { imgSrc } = this.state
        const { videoSrc } = this.state
        const { threeDObjectSrc } = this.state
        const url = uuidv4();
        this.setState({ chkVal: null, valMessage: null })
        if (imgSrc && this.state.Image) {
            const myFilename = "imgFile."
            const myNewCroppedFile = base64StringtoFile(imgSrc, myFilename)
            console.log(myNewCroppedFile)
            const params = {
                id: this.state.id,
                action: "Image",
                fullScrVideo: false,
                url: url
            };
            try {
                this.setState({ waiting: true, nextText: "Uploading" })
                const response = await API.graphql(graphqlOperation(uploadAsset, params));
                console.log(response)
                const file_name = "assetImage/" + url + '.jpg'
                await Storage.put(file_name, myNewCroppedFile)
                    .then(result => console.log(result))
                    .catch(err => console.log(err));
                this.props.location.state.assetType = "Image"
                this.props.location.state.itemUrl = this.state.videoSrc
                this.props.history.push({
                    pathname: `/datepicker/${this.state.id}`,
                    state: this.props.location.state
                })
            }
            catch (err) {
                console.log(err)
                this.setState({ waiting: false, nextText: "Next", chkVal: false, valMessage: err.errors[0].message })
            }
        }
        else if (videoSrc && this.state.Video) {
            const myFilename = "VideoFile."
            const myNewCroppedFile = base64StringtoFile(videoSrc, myFilename)
            console.log(myNewCroppedFile)
            const params = {
                id: this.state.id,
                action: "Video",
                fullScrVideo: "false",
                url: url
            };
            try {
                this.setState({ waiting: true, nextText: "Uploading" })
                const response = await API.graphql(graphqlOperation(uploadAsset, params));
                console.log(response)
                const file_name = "assetImage/" + url + '.mp4'
                await Storage.put(file_name, myNewCroppedFile)
                    .then(result => console.log(result))

                this.props.location.state.assetType = "Video"
                this.props.location.state.itemUrl = this.state.videoSrc
                this.props.location.state.fullScrVideo = this.state.fullScreenVideo
                this.props.history.push({
                    pathname: `/datepicker/${this.state.id}`,
                    state: this.props.location.state
                })
            }
            catch (err) {
                console.log(err)
                this.setState({ waiting: false, nextText: "Next", chkVal: false, valMessage: err.errors[0].message })
            }
        }
        else if (threeDObjectSrc && this.state.DObject) {
            const myFilename = "3DFile"
            const myNewCroppedFile = base64StringtoFile(threeDObjectSrc, myFilename)
            console.log(myNewCroppedFile)
            const params = {
                id: this.state.id,
                action: "3DObject",
                fullScrVideo: false,
                url: url
            };
            try {
                this.setState({ waiting: true, nextText: "Uploading" })
                const response = await API.graphql(graphqlOperation(uploadAsset, params));
                console.log(response)
                const file_name = "assetImage/" + url + '.obj'
                await Storage.put(file_name, myNewCroppedFile)
                    .then(result => console.log(result))

                this.props.location.state.assetType = "3DObject"
                this.props.location.state.itemUrl = this.state.threeDObjectSrc
                this.props.location.state.fullScrVideo = this.state.fullScreenVideo
                this.props.history.push({
                    pathname: `/datepicker/${this.state.id}`,
                    state: this.props.location.state
                })
            } catch (err) {
                console.log(err)
                this.setState({ waiting: false, nextText: "Next", chkVal: false, valMessage: err.errors[0].message })

            }
        }
        else {
            this.setState({ waiting: false, nextText: "Next", chkVal: false, valMessage: "Please Insert Asset" })
        }
    }

    handleBack = event => {
        this.props.history.push({
            pathname: `/croptagimage/${this.state.id}`,
            state: this.props.location.state
        })
    }

    handleClearToDefault = event => {
        if (this.state.Image) {
            this.setState({ imgSrc: "" })
        }
        if (this.state.Video) {
            this.setState({ videoSrc: "" })
        }
    }

    render() {
        const { imgSrc } = this.state
        const { videoSrc } = this.state
        const { threeDObjectSrc } = this.state
        return (
            <React.Fragment>
                {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Create AR Experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <div className="processTitleDiv">
                                <h3 className="processTitle">{this.state.title}</h3>
                            </div>
                            <div className="ProgressBar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconActive"> <img src="/assets/img/icon1.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon2.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon3.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon4.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon5.svg" alt="icon1"></img></Fab></td>
                                        </tr>
                                        <tr>
                                            <td><p className="iconRange">Upload Image</p></td>
                                            <td><p className="iconRange2">Crop Image</p></td>
                                            <td ><p className="iconRange2">Upload Asset</p></td>
                                            <td><p className="iconRange2">Effective Period</p></td>
                                            <td><p className="iconRange2">Call to Action</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg2"></img>
                        </div>
                        <br /> <br />
                        <div className="triggerAssetText">
                            <img src={this.state.tagImg} className="imgAsset" alt="tagimg" />
                            <h3>Trigger Asset</h3>
                        </div>
                        <br/>
                        {/* delete class when put 3dobj */}
                        <div  className="editButtongTable">
                            <table className="tableAsset">
                                <tbody>
                                    <tr>
                                        <td className="tdImg"><img src={this.state.tagImg} className="imgAssetResp" alt="tagimg" /></td>
                                        <td className="tdArrow"><img alt="icon1" src="/assets/img/img11.png" className="arrowDirection"></img></td>
                                        <td><Button variant="contained" className={this.state.Video ? "iconBtnSize iconImgPlace iconActive" : "iconBtnSize iconImgPlace iconNonActive"} onClick={this.handleInputVideo} id="videoAsset"><img src="/assets/img/icon3.svg" className="iconBtnSize" alt="icon1"></img></Button></td>
                                        <td><Button variant="contained" className={this.state.Image ? "iconBtnSize iconImgPlace2 iconActive" : "iconBtnSize iconImgPlace2 iconNonActive"} onClick={this.handleInputImage} id="imgAsset"><img src="/assets/img/icon9.svg" className="iconBtnSize" alt="icon1"></img></Button></td>
                                        <td className="hide"><Button variant="contained" className={this.state.DObject ? "iconBtnSize iconImgPlace2 iconActive hide" : "iconBtnSize iconImgPlace2 iconNonActive hide"} onClick={this.handleInput3DObject} id="3dAsset"><img src="/assets/img/icon8.svg" className="iconBtnSize" alt="icon1"></img></Button></td>
                                    </tr>
                                    <tr>
                                        <td className="tdImg"></td><td className="tdArrow"></td>
                                        <td><p className="assetTypeText">Video</p></td><td><p className="assetTypeText2">Image</p></td><td className="hide"><p className="assetTypeText2 hide2">3D Object</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            {this.state.Image && (
                                <div>
                                    {imgSrc !== "" ?
                                        <div>
                                            <div><img src={imgSrc} alt="uploadImg" className="arimgresponsive2" ></img></div><br />
                                            <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearToDefault} className={this.state.waiting ? "arButton8" : "arButton3"} disabled={this.state.waiting}>
                                                Clear</Fab>
                                        </div>
                                        :
                                        <div className="imgDrop">
                                            <br /><Dropzone onDrop={this.handleOnDrop} accept={acceptedImageTypes} multiple={false} maxSize={imageMaxSize} className="dropZone"></Dropzone>
                                        </div>
                                    }
                                </div>
                            )}

                            {this.state.Video && (
                                <div>
                                    {videoSrc !== "" ?
                                        <div>
                                            <div><video className="videoresponsive2" controls><source src={videoSrc} type="video/mp4" /></video></div><br />
                                            <br />
                                            <div className="hide">
                                                <Checkbox checked={this.state.fullScreenVideo} onChange={this.checkboxOnChange} name='fullScreenVideo' value='true' color="primary" disabled={this.state.waiting} />
                                                Video in Full Screen
                                            </div>
                                            <br />
                                            <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearToDefault} className={this.state.waiting ? "arButton8" : "arButton3"} disabled={this.state.waiting}>
                                                Clear</Fab>
                                        </div>
                                        :
                                        <div className="imgDrop">
                                            <br /><Dropzone onDrop={this.handleOnDrop} accept={acceptedVideoTypes} multiple={false} className="dropZone2"></Dropzone>
                                        </div>
                                    }
                                </div>
                            )}
                            {this.state.DObject && (
                                <div>
                                    {threeDObjectSrc !== "" ?
                                        <div>
                                            <div className="objposition"><ObjViewer model={threeDObjectSrc} /></div><br /><br />
                                            <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearToDefault} className={this.state.waiting ? "arButton8" : "arButton3"} disabled={this.state.waiting}>
                                                Clear</Fab>
                                        </div>
                                        :
                                        <div className="imgDrop">
                                            <br /><Dropzone onDrop={this.handleOnDrop} multiple={false} className="dropZone3"></Dropzone>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                        <br />
                        <div className="nextIcon">
                            <Fab variant="extended" size="small" aria-label="add" onClick={this.handleClick} className={this.state.waiting ? "arButton8" : "arButton3"} disabled={this.state.waiting}>
                                <div>{this.state.nextText}</div> &nbsp;&nbsp; <img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1"></img>
                            </Fab>
                        </div>
                        <br /><br />
                        {this.state.waiting && (<div><CircularProgress className="loading" /><p className="loadingText">Uploading</p></div>)}
                        <br /> <br />
                    </Typography>
                </Container>
                <br /><br />
            </React.Fragment>
        )
    }
}
export default ImgAsset