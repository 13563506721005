import React, { Component } from 'react'
import InsertTitle from '../components/InsertTitle'

class InsertTitlePage extends Component {

    render () {
        return (
            <div>
           <InsertTitle jobid={this.props.match.params.handle} {...this.props}></InsertTitle>
           </div>
        )
    }
}
export default InsertTitlePage