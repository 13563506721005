// eslint-disable
// this is an auto generated file. This will be overwritten

export const uploadFile = `mutation UploadFile(
  $id: String
  $title: String
  $url: String
  $createdDate: String
  $createdBy: String
) {
  uploadFile(
    id: $id
    title: $title
    url: $url
    createdDate: $createdDate
    createdBy: $createdBy
  )
}
`;
export const uploadAsset = `mutation UploadAsset(
  $id: String
  $action: String
  $fullScrVideo: String
  $url: String
) {
  uploadAsset(id: $id, action: $action, fullScrVideo: $fullScrVideo, url: $url)
}
`;
export const setDatePeriod = `mutation SetDatePeriod($id: String, $startDate: String, $endDate: String) {
  setDatePeriod(id: $id, startDate: $startDate, endDate: $endDate)
}
`;
export const setApi = `mutation SetApi(
  $id: String
  $status: String
  $buttonTitle: String
  $buttonLink: String
  $interupt: String
) {
  setApi(
    id: $id
    status: $status
    buttonTitle: $buttonTitle
    buttonLink: $buttonLink
    interupt: $interupt
  )
}
`;
export const updateFile = `mutation UpdateFile(
  $id: String
  $title: String
  $action: String
  $startDate: String
  $endDate: String
  $buttonLink: String
  $buttonTitle: String
  $fullScrVideo: String
  $status: String
  $tagUrl: String
  $assetUrl: String
  $resetTag: String
  $resetAsset: String
  $createdDate: String
) {
  updateFile(
    id: $id
    title: $title
    action: $action
    startDate: $startDate
    endDate: $endDate
    buttonLink: $buttonLink
    buttonTitle: $buttonTitle
    fullScrVideo: $fullScrVideo
    status: $status
    tagUrl: $tagUrl
    assetUrl: $assetUrl
    resetTag: $resetTag
    resetAsset: $resetAsset
    createdDate: $createdDate
  )
}
`;
export const createArImages = `mutation CreateArImages($input: CreateArImagesInput!) {
  createArImages(input: $input) {
    id
    title
    tagImageUrl
    action
    itemUrl
    startDate
    endDate
    status
    buttonTitle
    buttonLink
    fullScrVideo
    interupt
    createdDate
    createdBy
  }
}
`;
export const updateArImages = `mutation UpdateArImages($input: UpdateArImagesInput!) {
  updateArImages(input: $input) {
    id
    title
    tagImageUrl
    action
    itemUrl
    startDate
    endDate
    status
    buttonTitle
    buttonLink
    fullScrVideo
    interupt
    createdDate
    createdBy
  }
}
`;
export const deleteArImages = `mutation DeleteArImages($input: DeleteArImagesInput!) {
  deleteArImages(input: $input) {
    id
    title
    tagImageUrl
    action
    itemUrl
    startDate
    endDate
    status
    buttonTitle
    buttonLink
    fullScrVideo
    interupt
    createdDate
    createdBy
  }
}
`;
