import React, { Component } from 'react'
import ImgDrop from '../components/ImgDrop'

class UploadTagImagesPages extends Component {

    render () {
        return (
            <div>
           <ImgDrop jobid={this.props.match.params.handle} {...this.props}></ImgDrop>
           </div>
        )
    }
}
export default UploadTagImagesPages
