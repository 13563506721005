import React, { Component } from 'react'
import EditARExp from '../components/EditARExp'

class EditARExpPage extends Component {

    render(){
        return(<div>
             <EditARExp jobid={this.props.match.params.handle} {...this.props}></EditARExp>
        </div>

    )
}
}
export default EditARExpPage