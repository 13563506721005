// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:8675b48d-c86d-41b4-9ac6-834efa3d5e86",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_RFzoSUX2M",
    "aws_user_pools_web_client_id": "4ibvquarm4iuc1r2akgsnqp1e3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ueqo22v4jnhnbk4zp7qfblx25i.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uorzfuy3mbc2rgnfzktnb2bkri",
    "aws_content_delivery_bucket": "appxrun-local",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d2cfrfnaibhxg2.cloudfront.net",
    "aws_user_files_s3_bucket": "cms-website57e6537ef7e24d6c87da5daf73759696-local",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
