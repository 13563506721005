import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import { base64StringtoFile, image64toCanvasRef } from './ResuableUtils'
import ReactCrop from 'react-image-crop'
import Button from '@material-ui/core/Button';
import { SingleDatePicker } from 'react-dates';
import { updateFile } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Dropzone from 'react-dropzone'
import Moment from 'react-moment';
import { Checkbox } from '@material-ui/core';
import { ObjViewer } from 'react-obj-viewer';
import { Storage } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';

const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const imageMaxSize = 1000000000 // bytes
const acceptedImageTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedVideoTypes = 'video/mpeg, video/ogg, video/mp4'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const acceptedImagesTypesArray = acceptedImageTypes.split(",").map((item) => { return item.trim() })
const acceptedVideoTypesArray = acceptedVideoTypes.split(",").map((item) => { return item.trim() })
const uuidv4 = require('uuid/v4');
class EditARExp extends Component {
    constructor(props) {
        super(props)
        this.imagePreviewCanvasRef = React.createRef()
        this.fileInputRef = React.createRef()
        this.state = {
            id: this.props.location.state.id,
            title: this.props.location.state.title,
            tagImageUrl: this.props.location.state.tagImageUrl,
            tagImgSrc: this.props.location.state.tagImageUrl,
            imgSrcExt: null,
            crop: {
                unit: 'px',
                x: 130,
                y: 50,
                width: 200,
                height: 200
            },
            chckCrop: null,
            assetImgSrc: " ",
            assetVideoSrc: " ",
            asset3DObjectsrc: " ",
            resetTag: false,
            resetAsset: false,
            Image: false,
            Video: false,
            DObject: false,
            action: " ",
            status: " ",
            fullScreenVideo: false,
            startDate: null,
            endDate: null,
            displayStartDate: " ",
            displayEndDate: " ",
            displaySD: " ",
            displayED: " ",
            buttonTitle: " ",
            buttonLink: " ",
            url: " ",
            waiting: false,
            chkVal: null,
            valMessage: null,
            editType: null
        }
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleButtonTitleChange = this.handleButtonTitleChange.bind(this);
        this.handleButtonLinkChange = this.handleButtonLinkChange.bind(this);
    }

    componentDidMount() {
        // this.forceUpdate()
        // this.checkboxOnChange = this.checkboxOnChange.bind(this)
        // this.setChanges = this.setChanges.bind(this)
        if (this.props.location.state.status) { this.setState({ status: this.props.location.state.status }) }
        if (this.props.location.state.startDate) { this.setState({ displayStartDate: this.props.location.state.startDate }, this.handlerCheckDate) }
        if (this.props.location.state.endDate) { this.setState({ displayEndDate: this.props.location.state.endDate }, this.handlerCheckDate) }
        if (this.props.location.state.buttonLink) { if (this.props.location.state.buttonLink === "No-Action") { } else { this.setState({ buttonLink: this.props.location.state.buttonLink }) } }
        if (this.props.location.state.buttonTitle) { if (this.props.location.state.buttonTitle === "No-Action") { } else { this.setState({ buttonTitle: this.props.location.state.buttonTitle }) } }
        if (this.props.location.state.url) { this.setState({ url: this.props.location.state.url }) }
        if (this.props.location.state.action) { this.setState({ action: this.props.location.state.action }, this.handlerCheck) }
        // if (this.props.location.state.fullScreenVideo) { this.setState({ fullScreenVideo: Boolean(this.props.location.state.fullScreenVideo) }, this.handlerCheck) }

    }

    handleBack = event => {
        this.props.history.push("/index.html")
    }

    handlerCheckDate = async (event) => {
        if (this.state.displayStartDate !== " " && this.state.displayEndDate !== " ") {
            var strdate = new Date(this.state.displayStartDate * 1000);
            var enddate = new Date(this.state.displayEndDate * 1000);
            var sdate = new Date(strdate);
            var edate = new Date(enddate);
            var sDate = sdate.getDate()
            var sMonth = sdate.getMonth() + 1
            var eDate = edate.getDate()
            var eMonth = edate.getMonth() + 1
            if (sDate < 10) { sDate = '0' + sDate; } if (sMonth < 10) { sMonth = '0' + sMonth; }
            if (eDate < 10) { eDate = '0' + eDate; } if (eMonth < 10) { eMonth = '0' + eMonth; }
            const sdd = sDate + '/' + sMonth + '/' + sdate.getFullYear();
            const edd = eDate + '/' + eMonth + '/' + edate.getFullYear();
            this.setState({ displaySD: sdd, displayED: edd })
        }
    }
    handlerCheck = async (event) => {
        if (this.state.action === "Video") {
            this.setState({ assetVideoSrc: this.props.location.state.itemUrl })
            this.handleInputVideo();
        }
        else if (this.state.action === "Image") {
            this.setState({ assetImgSrc: this.props.location.state.itemUrl })
            this.handleInputImage();
        }
        else {
            this.setState({ asset3DObjectsrc: this.props.location.state.itemUrl })
            this.handleInput3DObject();
        }
    }

    setChanges(name, value) {
        return new Promise(resolve => this.setState((state, props) => ({
            [name]: value
        }), resolve))
    }

    checkboxOnChange(event) {
        this.setChanges(event.target.name, event.target.checked)
    }

    handleInputImage = async event => { this.setState({ Image: true, Video: false, DObject: false }); }

    handleInputVideo = async event => { this.setState({ Image: false, Video: true, DObject: false }); }

    handleInput3DObject = async event => { this.setState({ Image: false, Video: false, DObject: true }); }

    handleTitleChange(event) {
        this.setState({ title: event.target.value });
    }

    handleButtonTitleChange(event) {
        this.setState({ buttonTitle: event.target.value });
    }

    handleButtonLinkChange(event) {
        this.setState({ buttonLink: event.target.value });
    }

    handleClearAssetToDefault = event => {
        if (this.state.Image) {
            this.setState({ assetImgSrc: " ", resetAsset: true })
        }
        if (this.state.Video) {
            this.setState({ assetVideoSrc: " ", resetAsset: true })
        }
        if (this.state.DObject) {
            this.setState({ asset3DObjectsrc: " ", resetAsset: true })
        }
    }

    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }

    verifyAssetFile = (files) => {
        this.setState({ chkVal: null, valMessage: null })
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (this.state.Image) {
                if (currentFileSize > imageMaxSize) {
                    this.setState({ waiting: false, chkVal: false, valMessage: "This file is not allowed. " + currentFileSize + " bytes is too large" })
                    return false
                }
                if (!acceptedImagesTypesArray.includes(currentFileType)) {
                    this.setState({ waiting: false, chkVal: false, valMessage: "This file is not allowed. Only images are allowed." })
                    return false
                }
            }
            if (this.state.Video) {
                if (!acceptedVideoTypesArray.includes(currentFileType)) {
                    this.setState({ waiting: false, chkVal: false, valMessage: "This file is not allowed. Only Video are allowed." })
                    return false
                }
            }
            // if (this.state.DObject) {
            //     if (!accepted3DObjestTypesArray.includes(currentFileType)) {
            //        
            //         return false
            //     }
            // }
            return true
        }
    }

    handleOnDropAsset = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyAssetFile(rejectedFiles)
        }
        if (files && files.length > 0) {
            const isVerified = this.verifyAssetFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = myFileItemReader.result
                    if (this.state.Image) {
                        this.setState({
                            assetImgSrc: myResult,
                            resetAsset: true
                        })
                    }
                    else if (this.state.Video) {
                        this.setState({
                            assetVideoSrc: myResult,
                            resetAsset: true
                        })
                    }
                    else if (this.state.DObject) {
                        this.setState({
                            asset3DObjectsrc: myResult,
                            resetAsset: true
                        })
                    }
                }, false)
                myFileItemReader.readAsDataURL(currentFile)

            }
        }
    }

    verifyFile = (files) => {
        this.setState({ chkVal: null, valMessage: null })
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > imageMaxSize) {
                this.setState({ waiting: false, chkVal: false, valMessage: "This file is not allowed. " + currentFileSize + " bytes is too large" })
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                this.setState({ waiting: false, chkVal: false, valMessage: "This file is not allowed. Only images are allowed." })
                return false
            }
            return true
        }
    }

    handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = myFileItemReader.result
                    this.setState({
                        tagImageUrl: myResult,
                        resetTag: true,
                    })
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            }
        }
    }
    handleClearToDefault = event => {
        if (event) event.preventDefault()
        this.setState({
            tagImageUrl: " ",
            resetTag: true,
            chckCrop: false
        })
    }

    handleImageLoaded = (image) => {
        console.log(image)
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop: crop })
    }

    handleOnCropComplete = (crop, pixelCrop) => {
        console.log(crop, pixelCrop)
        this.setState({
            chckCrop: true
        })
        const canvasRef = this.imagePreviewCanvasRef.current
        const { tagImageUrl } = this.state
        image64toCanvasRef(canvasRef, tagImageUrl, pixelCrop)
    }

    handlerDate = async (event) => {
        if (this.state.startDate != null) {
            var strD = new Date(this.state.startDate._d);
            var datum = new Date(strD.getFullYear(),strD.getMonth(),strD.getDate()-1,strD.getHours()+12,strD.getMinutes(),strD.getSeconds());
            var sdd =datum.getTime()/1000;
            this.setState({ displayStartDate: sdd })
        }
        if (this.state.endDate != null) {
            var endD = new Date(this.state.endDate._d);
            var datum2 = new Date(endD.getFullYear(),endD.getMonth(),endD.getDate(),endD.getHours()+11,endD.getMinutes(),endD.getSeconds());
            var edd =datum2.getTime()/1000;
            this.setState({ displayEndDate: edd })
        }
    }
    handleEdit(item) {
        this.setState({ editType: item }, this.handleClick)
    }

    handleClick = async (event) => {
        this.setState({ chkVal: null, valMessage: null })
        const { assetImgSrc } = this.state
        const { assetVideoSrc } = this.state
        const { asset3DObjectsrc } = this.state
        const { tagImageUrl } = this.state
        const tagurl = uuidv4();
        const asseturl = uuidv4();
        var status = this.state.editType
        if (this.state.title.length < 1) {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please insert the title" })
            return;
        }
        if (this.state.buttonTitle.length > 20) {
            this.setState({ waiting: false, chkVal: false, valMessage: "Button title should be less than 20 words" })
            return;
        }
        if (tagImageUrl === " ") {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please insert the tag image" })
            return;
        }
        if (this.state.startDate && this.state.endDate) {
            var strD = new Date(this.state.startDate._d);
            var endD = new Date(this.state.endDate._d);
            if (strD > endD) {
                this.setState({ waiting: false, chkVal: false, valMessage: "End date cannot be ealier than start date" })
                return;
            }
        }
        if (this.state.displayStartDate === " " || this.state.displayEndDate === " ") {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please select an effective period" })
            return;
        }
        if (assetImgSrc === " " && assetVideoSrc === " " && asset3DObjectsrc === " ") {
            this.setState({ waiting: false, chkVal: false, valMessage: "Please insert an asset" })
            return;
        }
        if (this.state.resetAsset) {
            if (assetImgSrc === " " && this.state.Image) {
                this.setState({ waiting: false, chkVal: false, valMessage: "Please insert an asset image" })
                return;
            }
            else if (assetVideoSrc === " " && this.state.Video) {
                this.setState({ waiting: false, chkVal: false, valMessage: "Please insert an asset video" })
                return;
            }
            else if (asset3DObjectsrc === " " && this.state.DObject) {
                this.setState({ waiting: false, chkVal: false, valMessage: "Please insert an asset 3d object" })
                return;
            }
        }
        if (this.state.resetTag) {               //check whether the tag image need update
            if (tagImageUrl !== " ") {
                if (this.state.chckCrop) {
                    const canvasRef = this.imagePreviewCanvasRef.current
                    const { imgSrcExt } = this.state
                    this.setState({ waiting: true })
                    const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)
                    const myFilename = "previewFile." + imgSrcExt
                    const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
                    console.log(myNewCroppedFile)
                    const file_name = "tagImage/" + tagurl + '.jpg'
                    try {
                        await Storage.put(file_name, myNewCroppedFile)
                            .then(result => console.log(result))
                            .catch(err => console.log(err));
                    }
                    catch (err) {
                        console.log(err)
                        this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
                    }
                } else {
                    this.setState({ waiting: false, chkVal: false, valMessage: "Please crop an image" })
                    return;
                }
            }
            else {
                this.setState({ waiting: false, chkVal: false, valMessage: "Please insert Tag image" })
                return;
            }
        }
        if (this.state.resetAsset) {       //check whether the asset image need update
            if (assetImgSrc !== " " && this.state.Image) {
                const myFilename = "imgFile."
                const myNewCroppedFile = base64StringtoFile(assetImgSrc, myFilename)
                console.log(myNewCroppedFile)
                await this.setState({ action: "Image" })
                const file_name = "assetImage/" + asseturl + '.jpg'
                try {
                    this.setState({ waiting: true })
                    await Storage.put(file_name, myNewCroppedFile)
                        .then(result => console.log(result))
                        .catch(err => console.log(err));
                } catch (err) {
                    console.log(err)
                    this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
                }
            }
            else if (assetVideoSrc !== " " && this.state.Video) {
                const myFilename = "VideoFile."
                const myNewCroppedFile = base64StringtoFile(assetVideoSrc, myFilename)
                console.log(myNewCroppedFile)
                await this.setState({ action: "Video" })
                const file_name = "assetImage/" + asseturl + '.mp4'
                try {
                    this.setState({ waiting: true })
                    await Storage.put(file_name, myNewCroppedFile)
                        .then(result => console.log(result))
                }
                catch (err) {
                    console.log(err)
                    this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
                }
            }
            else if (asset3DObjectsrc !== " " && this.state.DObject) {
                const myFilename = "3DFile"
                const myNewCroppedFile = base64StringtoFile(asset3DObjectsrc, myFilename)
                console.log(myNewCroppedFile)
                await this.setState({ action: "3DObject" })
                const file_name = "assetImage/" + asseturl + '.obj'
                try {
                    this.setState({ waiting: true })
                    await Storage.put(file_name, myNewCroppedFile)
                        .then(result => console.log(result))
                }
                catch (err) {
                    console.log(err)
                    this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
                }
            }
        }
        var btnTitle = this.state.buttonTitle
        var btnLink = this.state.buttonLink
        if (this.state.buttonTitle === " ") {
            btnTitle = "No-Action"
            btnLink = "No-Action"
        }
        try {
            const nowDate = new Date();
            const createdDate = String(nowDate.toISOString())
            const params = {
                id: this.state.id,
                title: this.state.title,
                action: this.state.action,
                startDate: this.state.displayStartDate,
                endDate: this.state.displayEndDate,
                buttonLink: btnLink,
                buttonTitle: btnTitle,
                fullScrVideo: "false",
                status: status,
                tagUrl: tagurl,
                assetUrl: asseturl,
                resetTag: this.state.resetTag,
                resetAsset: this.state.resetAsset,
                createdDate: createdDate
            };
            this.setState({ waiting: true })
            const response = await API.graphql(graphqlOperation(updateFile, params));
            console.log(response)
            await this.setState({ waiting: false, chkVal: true, valMessage: "Update successfully" })
            setTimeout(this.handleBack, 1000);
            // this.props.history.push({
            //     pathname: `/`
            // })
        } catch (err) {
            console.log(err)
            this.setState({ waiting: false, chkVal: false, valMessage: err.errors[0].message })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.chkVal === true && (<div className="alert alert-success sticky" role="alert">
                    <button type="button" className="close-alert">×</button>{this.state.valMessage}
                </div>)}
                {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Edit AR experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <input type="text" id="fname" placeholder="What's your AR experience name? *" name="fname" value={this.state.title} onChange={this.handleTitleChange} className="titleName" />
                        </div>
                        <br /><br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg"></img></div>
                        <br /> <br /> <br />
                        <br /><h3>Tag image</h3><br />
                        {this.state.resetTag&&(<div><h3>Drop and crop image</h3><br/></div>)}
                        <div >
                            <div className="tagImg">
                                {this.state.tagImageUrl !== " " ?
                                    <div>
                                        {this.state.resetTag ?
                                            <div>
                                                <div>
                                                    <ReactCrop src={this.state.tagImageUrl} crop={this.state.crop} onImageLoaded={this.handleImageLoaded} onComplete={this.handleOnCropComplete} onChange={this.handleOnCropChange} minWidth={Number("80")} minHeight={Number("80")}/>
                                                </div>
                                                <div>
                                                    <canvas ref={this.imagePreviewCanvasRef} className="canvaRef"></canvas>
                                                </div>

                                            </div>
                                            :
                                            <div >
                                                <img src={this.state.tagImageUrl} alt="arrow" className="dropImageEdit" />
                                            </div>}
                                    </div>
                                    :
                                    <div className="imgDrop">
                                        <Dropzone onDrop={this.handleOnDrop} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize} className="dropZone" ></Dropzone>
                                    </div>
                                }
                            </div>
                            <br /><br />
                            <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearToDefault} className="clearBtn arButton9">Clear</Fab>
                            <br /><br /><br />
                            <div>
                                <h3>Image asset</h3><br />
                                <div className="ButtonTable">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><Button variant="contained" className={this.state.Video ? "iconBtnSize iconImgPlaceEdit iconActive" : "iconBtnSize iconImgPlaceEdit iconNonActive"} onClick={this.handleInputVideo} id="videoAsset"><img src="/assets/img/icon3.svg" className="iconBtnSize" alt="icon1"></img></Button></td>
                                                <td><Button variant="contained" className={this.state.Image ? "iconBtnSize iconImgPlaceEdit iconActive" : "iconBtnSize iconImgPlaceEdit iconNonActive"} onClick={this.handleInputImage} id="imgAsset"><img src="/assets/img/icon9.svg" className="iconBtnSize" alt="icon1"></img></Button></td>
                                                <td className="hide"><Button variant="contained" className={this.state.DObject ? "iconBtnSize iconImgPlaceEdit iconActive" : "iconBtnSize iconImgPlaceEdit iconNonActive"} onClick={this.handleInput3DObject} id="3dAsset"><img src="/assets/img/icon8.svg" className="iconBtnSize" alt="icon1"></img></Button></td>
                                            </tr>
                                            <tr>
                                                <td><p className="assetTypeText">Video</p></td><td><p className="assetTypeText">Image</p></td><td><p className="assetTypeText hide">3D Object</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                                <div>
                                    <div>
                                        {this.state.Image && (
                                            <div>
                                                {this.state.assetImgSrc !== " " ?
                                                    <div>
                                                        <div><img src={this.state.assetImgSrc} alt="uploadImg" className="dropImageEdit" ></img></div><br />
                                                        <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearAssetToDefault} className="arButton9" disabled={this.state.waiting}>
                                                            Clear</Fab>
                                                    </div>
                                                    :
                                                    <div className="imgDrop">
                                                        <br /><Dropzone onDrop={this.handleOnDropAsset} accept={acceptedImageTypes} multiple={false} maxSize={imageMaxSize} className="dropZone"></Dropzone>
                                                    </div>
                                                }
                                            </div>
                                        )}

                                        {this.state.Video && (
                                            <div>
                                                {this.state.assetVideoSrc !== " " ?
                                                    <div>
                                                        <div><video className="videoresponsive2Edit" controls><source src={this.state.assetVideoSrc} type="video/mp4" /></video></div><br />
                                                        <br />
                                                        <div className="hide">
                                                            <Checkbox checked={false} onChange={this.checkboxOnChange} name='fullScreenVideo' value='true' color="primary" />
                                                            Video in Full Screen
                                                        </div>
                                                        <br />
                                                        <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearAssetToDefault} className="arButton9" disabled={this.state.waiting}>
                                                            Clear</Fab>
                                                    </div>
                                                    :
                                                    <div className="imgDrop">
                                                        <br /><Dropzone onDrop={this.handleOnDropAsset} accept={acceptedVideoTypes} multiple={false} className="dropZone2"></Dropzone>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        {this.state.DObject && (
                                            <div>
                                                {this.state.asset3DObjectsrc !== " " ?
                                                    <div>
                                                        <div className="objposition"><ObjViewer model={this.state.asset3DObjectsrc} /></div><br /><br />
                                                        <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleClearAssetToDefault} className="arButton9" disabled={this.state.waiting}>
                                                            Clear</Fab>
                                                    </div>
                                                    :
                                                    <div className="imgDrop">
                                                        <br /><Dropzone onDrop={this.handleOnDropAsset} multiple={false} className="dropZone3"></Dropzone>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div><br /><br />
                            <div>
                                <h3>Effective period</h3><br />
                                {this.state.displayStartDate !== " " && this.state.displayEndDate !== " " ?
                                    <p className="effDateText">AR experience will be effective from  <Moment unix format="DD/MM/YYYY">{this.state.displayStartDate}</Moment> to  <Moment unix format="DD/MM/YYYY">{this.state.displayEndDate}</Moment></p> : <p></p>}
                                <div>
                                    <br />
                                    <table className="datePickerTb">
                                        <tbody>
                                            <tr>
                                                <td><div className="dateFormat">Start date:</div></td>
                                                <td> <SingleDatePicker
                                                    date={this.state.startDate} // momentPropTypes.momentObj or null
                                                    onDateChange={startDate => this.setState({ startDate }, this.handlerDate)} // PropTypes.func.isRequired
                                                    focused={this.state.focused} // PropTypes.bool
                                                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                                    id="1" // PropTypes.string.isRequired,
                                                    displayFormat={() => "DD/MM/YYYY"}
                                                    placeholder={this.state.displaySD}
                                                /></td>
                                            </tr>
                                            <tr>
                                                <td><div className="dateFormat">End date:</div></td>
                                                <td>  <SingleDatePicker
                                                    date={this.state.endDate} // momentPropTypes.momentObj or null
                                                    onDateChange={endDate => this.setState({ endDate }, this.handlerDate)} // PropTypes.func.isRequired
                                                    focused={this.state.focused2} // PropTypes.bool
                                                    onFocusChange={({ focused: focused2 }) => this.setState({ focused2 })} // PropTypes.func.isRequired
                                                    id="2" // PropTypes.string.isRequired,
                                                    displayFormat={() => "DD/MM/YYYY"}
                                                    placeholder={this.state.displayED}
                                                /> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div><br /><br />
                            <div>
                                <h3>Call to action</h3>
                                <div>
                                    <TextField label="Button Title" value={this.state.buttonTitle} onChange={this.handleButtonTitleChange} margin="dense" className="apiBtnTitle" />
                                    <br />
                                    <TextField label="Button Link" value={this.state.buttonLink} onChange={this.handleButtonLinkChange} margin="dense" className="apiBtnLink" />
                                </div>
                            </div>
                            <br /><div>
                                <h3>AR experience status</h3>
                                <div>{this.state.status}</div>
                            </div>
                            <br /><br />
                            {this.state.status==="Draft"&&(
                            <div>
                            <Fab variant="extended" size="small" aria-label="add" onClick={() => this.handleEdit("Draft")}  className={this.state.waiting ? "arButton11" : "arButton4"} disabled={this.state.waiting}>
                                <div className="apiBtn">Save as Draft</div></Fab>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Fab variant="extended" size="medium" aria-label="add" onClick={() => this.handleEdit("Live")}  className={this.state.waiting ? "arButton10" : "arButton9"} disabled={this.state.waiting}>Publish</Fab>
                            </div>
                            )}
                            {this.state.status==="Live"&&(
                            <div>
                            <Fab variant="extended" size="medium" aria-label="add" onClick={() => this.handleEdit("Live")}  className={this.state.waiting ? "arButton10" : "arButton9"} disabled={this.state.waiting}>Save</Fab>
                            </div>
                            )}
                            <br /> <br />
                            {this.state.waiting && (
                                <div>
                                    <CircularProgress className="loading" /><p className="loadingText">Uploading</p>
                                </div>)}
                        </div>
                        <br /><br />
                    </Typography>
                    <br />
                    <br />
                </Container>

            </React.Fragment>
        )
    }
}
export default EditARExp