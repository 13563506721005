import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import { uploadFile } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import { base64StringtoFile, image64toCanvasRef } from './ResuableUtils'
import { Storage } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

const uuidv4 = require('uuid/v4');
class ImgCrop extends Component {
    constructor(props) {
        super(props)
        this.imagePreviewCanvasRef = React.createRef()
        this.fileInputRef = React.createRef()
        this.state = {
            id: this.props.location.state.id,
            title: this.props.location.state.title,
            imgSrc: this.props.location.state.tagImageUrl,
            imgSrcExt: null,
            crop: {                          // crop: { aspect: 1 / 1 },
                unit: 'px',
                x: 400,
                y: 400,
                width: 400,
                height: 400,
            },
            chckCrop: null,
            chkVal: null,
            valMessage: null,
            groupName: this.props.location.state.groupName,
            cropSizeVal: null,
            nextText: "Next"
        }
    }

    handleBack = event => {
        this.props.history.push({
            pathname: `/uploadtagimage/${this.state.id}`,
            state: this.props.location.state
        })
    }

    handleImageLoaded = (image) => {
        console.log(image)
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop: crop })
    }
    
    handleClose = event => {
        this.setState({ chkVal: null, valMessage: null })
    }

    handleOnCropComplete = (crop, pixelCrop) => {
        console.log(crop, pixelCrop)
        if(pixelCrop.height<400||pixelCrop.width<400){
            this.setState({
               cropSizeVal: false
            })
        }
        else{
            this.setState({
                cropSizeVal: true
             })
        }
        this.setState({
            chckCrop: true
        })
        const canvasRef = this.imagePreviewCanvasRef.current
        const { imgSrc } = this.state
        image64toCanvasRef(canvasRef, imgSrc, pixelCrop)
    }

    handleDownloadClick = async (event) => {
        event.preventDefault()
        const { imgSrc } = this.state
        this.setState({chkVal: null, valMessage: null})
        if (imgSrc) {

            const canvasRef = this.imagePreviewCanvasRef.current
            const { imgSrcExt } = this.state
        if (this.state.cropSizeVal){    
            if (this.state.chckCrop) {
                const url = uuidv4();
                const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)
                const myFilename = "previewFile." + imgSrcExt
                const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
                console.log(myNewCroppedFile)
                const nowDate = new Date();
                const createdDate = String(nowDate.toISOString())
                console.log(this.state.groupName)
                const params = {
                    id: this.state.id,
                    title: this.state.title,
                    url: url,
                    createdDate: createdDate,
                    createdBy: this.props.location.state.groupName
                };
                try {
                    this.setState({ waiting: true, nextText: "Uploading" })
                    const file_name = "tagImage/" + url + '.jpg'
                    await Storage.put(file_name, myNewCroppedFile)
                        .then(result => console.log(result))
                        .catch(err => console.log(err));
                    const response = await API.graphql(graphqlOperation(uploadFile, params));
                    console.log(response)
                    this.props.location.state.tagImageUrl = this.state.imgSrc
                    this.props.location.state.title = this.state.title
                    this.props.history.push({
                        pathname: `/uploadasset/${this.state.id}`,
                        state: this.props.location.state
                    })
                }
                catch (err) {
                    console.log(err)
                    this.setState({ waiting: false, nextText: "Next", chkVal: false,valMessage:err.errors[0].message })
                }
            }
            
            else {
                this.setState({ waiting: false, nextText:"Next",chkVal: false,valMessage:"Please crop the image" })
            }
        }else{
            this.setState({ waiting: false, nextText:"Next",chkVal: false,valMessage:"Please crop the image at least 400px for height and width" })
        }
        }
    }

    render() {
        const { imgSrc } = this.state
        return (
            <React.Fragment>
                 {this.state.chkVal === false && (<div className="alert alert-danger sticky" role="alert">
                    <button type="button" className="close-alert" onClick={this.handleClose} >×</button>{this.state.valMessage}
                </div>)}
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Create AR Experience</h3>
                    </Typography>
                </Container>
                <br /><br />
                <br /><br />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleBody">
                        <div className="divHeader">
                            <div className="processTitleDiv">
                                <h3 className="processTitle">{this.state.title}</h3>
                            </div>
                            <div className="ProgressBar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconActive"> <img src="/assets/img/icon1.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconActive"> <img src="/assets/img/icon2.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon3.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon4.svg" alt="icon1"></img></Fab></td>
                                            <td><Fab aria-label="add" className="iconRange iconSize iconDet iconNonActive"> <img src="/assets/img/icon5.svg" alt="icon1"></img></Fab></td>
                                        </tr>
                                        <tr>
                                            <td><p className="iconRange">Upload Image</p></td>
                                            <td><p className="iconRange2">Crop Image</p></td>
                                            <td ><p className="iconRange2">Upload Asset</p></td>
                                            <td><p className="iconRange2">Effective Period</p></td>
                                            <td><p className="iconRange2">Call to Action</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                        <div className="backIcon"><img src="/assets/img/icon6.svg" onClick={this.handleBack} alt="icon1" className="backIconImg"></img></div>
                        <br /><br /> <br />
                        <div>
                            <ReactCrop src={imgSrc} crop={this.state.crop} onImageLoaded={this.handleImageLoaded} onComplete={this.handleOnCropComplete} onChange={this.handleOnCropChange} />
                        </div>
                        <br /><br /><div>
                            <canvas ref={this.imagePreviewCanvasRef} className="canvaRef"></canvas>
                        </div>
                        <div className="nextIcon4">
                            <Fab variant="extended" size="small" aria-label="add" onClick={this.handleDownloadClick} className={this.state.waiting ?  "arButton8" : "arButton3"} disabled={this.state.waiting}>
                               <div>{this.state.nextText}</div> &nbsp;&nbsp; <img src="/assets/img/icon7.svg" className="icon7Size" alt="icon1"></img>
                            </Fab>
                        </div>
                        <br /><br />
                        {this.state.waiting && (
                            <div>
                                <CircularProgress className="loading" /><p className="loadingText">Processing</p>
                            </div>)}
                        <br /><br />
                    </Typography>
                    <br />
                    <br />
                </Container>

            </React.Fragment>
        )
    }
} export default ImgCrop
