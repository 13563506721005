import React, { Component} from 'react'
import './App.css';
import BasePage from './pages/BasePage'
import './App.scss'; 
import { Auth } from 'aws-amplify';
import { withAuthenticator,SignIn,ConfirmSignIn,RequireNewPassword } from 'aws-amplify-react';

class App extends Component {
  state={
    isAuthenticated: false,
    userGroup: null
  }
  setAuthStatus = authenticated=>{
    this.setState({isAuthenticated: authenticated});
  }
  setUserGroup = group=>{
    this.setState({userGroup: group})
  }
  async componentDidMount(){
    try{
    const session = await Auth.currentSession();
    this.setAuthStatus(true);
    this.setUserGroup(session.idToken.payload['cognito:groups'][0])
    console.log(session);
    }
    catch(error){
      console.log(error)
    }
  }
  render() {
    const authProps={
      isAuthenticated: this.state.isAuthenticated,
      setAuthStatus: this.setAuthStatus,
      userGroup : this.state.userGroup,
      setUserGroup: this.setUserGroup,
    }

    return (
      <React.Fragment> 
        <BasePage auth={authProps}/> 
      </React.Fragment> 
    );
  }
}
export default withAuthenticator( App , false, [ 
  <ConfirmSignIn/>, 
  <RequireNewPassword />,  
  <SignIn/> 
])