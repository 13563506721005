import React, { Component } from 'react'
import ImgCrop from '../components/ImgCrop'

class CropTagImagePage extends Component {

    render () {
        return (
            <div>
           <ImgCrop jobid={this.props.match.params.handle} {...this.props}></ImgCrop>
           </div>
        )
    }
}
export default CropTagImagePage