// eslint-disable
// this is an auto generated file. This will be overwritten

export const getArImages = `query GetArImages($id: ID!) {
  getArImages(id: $id) {
    id
    title
    tagImageUrl
    action
    itemUrl
    startDate
    endDate
    status
    buttonTitle
    buttonLink
    fullScrVideo
    interupt
    createdDate
    createdBy
  }
}
`;
export const listArImagess = `query ListArImagess(
  $filter: ModelArImagesFilterInput
  $limit: Int
  $nextToken: String
) {
  listArImagess(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      tagImageUrl
      action
      itemUrl
      startDate
      endDate
      status
      buttonTitle
      buttonLink
      fullScrVideo
      interupt
      createdDate
      createdBy
    }
    nextToken
  }
}
`;
