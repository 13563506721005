import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import InfiniteScroll from "react-infinite-scroller";
import Moment from 'react-moment';

const uuidv4 = require('uuid/v4');
class ArList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobid: null,
            items: 4,
            hasMoreItems: true,
            scrollBot: false,
            groupName: this.props.auth.userGroup
        }
        this.handleEdit = this.handleEdit.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleARExp = event => {
        this.setState((state, props) => ({ jobid: uuidv4() }), this.handlerSubmit)
    }

    handlerSubmit = (event) => {
        this.props.history.push({
            pathname: `/inserttitle/${this.state.jobid}`,
            state: { id: this.state.jobid, title: "", tagImageUrl: "", assetType: "", itemUrl: "", startDate: "", endDate: "", fullScrVideo: false,  groupName: this.props.auth.userGroup }
        })
    }

    handleEdit(item) {
        this.props.history.push({
            pathname: `/editarexp/${item.id}`,
            state: item,
        })
    }

    loadMore() {
        var chck = true
        if (this.mounted) {
            if (this.state.items >= this.props.arExperienceList.length && this.props.arExperienceList.length > 0) {
                this.setState({ hasMoreItems: false, scrollBot: false });
                chck = false
            }
            else if (this.state.scrollBot && chck) {
                setTimeout(() => {
                    this.setState({ items: this.state.items + 4 });
                }, 100);
            }
        }
    }

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset + 2;
        if (windowBottom >= docHeight) {
            this.setState({
                scrollBot: true
            });
        } else {
            this.setState({
                scrollBot: false
            });
        }
    }

    componentDidMount() {
        this.mounted = true;
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("scroll", this.handleScroll);
    }
    sortByDate() {
        const arList = this.props.arExperienceList
        let newList = arList
        newList.sort(function (a, b) {
            var adate = new Date(a.createdDate);
            var bdate = new Date(b.createdDate)
            if (adate > bdate) return -1;
            else if (adate < bdate) return 1;
            else return 0;
        });
        return newList
    }

    render() {
        var items;
        var items2;
        if (this.props.arExperienceList.length>0) {
            const newList = this.sortByDate()
            var arExpList = [];
            for (var i = 0; i < this.state.items && i < newList.length; i++) {
                arExpList.push(newList[i])
            }
            items = arExpList.map((item, key) =>
                <div key={item.id}>
                    <Container maxWidth="lg" onClick={() => this.handleEdit(item)} className="listItem">
                        <Typography component="div" className="containerStyleNewAR">
                            <div className="listImg">
                                <img src={item.tagImageUrl} className="list" alt="assetimg"></img>
                            </div>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><h2 className="arObjectTitle">{item.title}</h2></td>
                                        </tr>
                                        <tr>
                                            <td> {item.status === "Live" ? <div className="arObjectDate">Effective Date : <Moment unix format="DD/MM/YYYY">{item.startDate}</Moment> to  <Moment unix format="DD/MM/YYYY">{item.endDate}</Moment></div> : <div className="arObjectDate"></div>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div >
                                <table className="arObjText">
                                    <tbody>
                                        <tr>
                                            <td className="tdText">
                                                <div className="arObjectText">Asset type : {item.action}</div></td>
                                            <td className="tdText3">
                                                <div className={item.status === "Live" ? "arObjectText3 liveText" : "arObjectText3 draftText"}>{item.status}</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Typography>
                    </Container>
                    <br />
                </div>
            );
        }
        else {
            items2 =
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleNewExp">
                        <img className="arimgresponsive" src="/assets/img/img7.png" alt="arExperience" />
                        <h3 className="arExperienceTitle">Create Your New First AR Experience</h3>
                        <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleARExp} className="arButton2"> New Experience </Fab>
                    </Typography>
                </Container>
        }
        return (
            <React.Fragment>
                <br /><br />
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="div" className="containerStyleHeader">
                        <h3 className="title">Listing Page</h3>
                        <Fab variant="extended" size="medium" aria-label="add" onClick={this.handleARExp} className="arButton"> New Experience </Fab>
                    </Typography>
                </Container>
                <br /><br />
                {items ? <div>
                    <div style={{ height: "auto" }}>
                        <InfiniteScroll
                            loadMore={this.loadMore.bind(this)}
                            hasMore={this.state.hasMoreItems}
                            loader={<div className="loader" key={uuidv4()}> Loading... </div>}
                            useWindow={false}>
                            {items}{" "}
                        </InfiniteScroll>{" "}
                    </div>{" "}
                    {/* {items} */}
                    <br /> </div> : <div>{items2}<br /></div>}
            </React.Fragment>
        )
    }
}

export default withRouter(ArList);