import React, { Component } from 'react'
import EffectiveDate from '../components/EffectiveDate'

class DatePickerPage extends Component {
    
    render () {
        console.log(this.props)
        return (
      <div>
          <EffectiveDate jobid={this.props.match.params.handle} {...this.props}></EffectiveDate>
      </div>
        )
    }
}
export default DatePickerPage 